import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
// import Publicity from "pages/publicity/publicity";
import Ifg from "pages/ifg/ifg";

function App() {
    return (
        <Router>
            <Routes>
                {/* <Route path="/" index element={<Publicity />} />
                <Route path="/publicity" element={<Publicity />} />
                <Route path="*" element={<Navigate to="/" replace />} /> */}

                <Route path="/" index element={<Ifg />} />
                <Route path="/ifg" element={<Ifg />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
