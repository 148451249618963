import {useState, useEffect, useCallback} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Autoplay, Pagination} from "swiper";
import "swiper/css";
import "./ifg.css";
import {ToastContainer} from "react-toastify";
import RequestDemo from "./components/requestDemo";
import Contact from "./components/contact";

import topBgi from "assets/images/ifg/top-bgi.png";
import topBgiMobile from "assets/images/ifg/top-bgi-mobile.png";
import logo from "assets/images/ifg/logo.png";
import menu from "assets/images/ifg/menu.png";
import arrowDown from "assets/images/ifg/arrow-down.png";
import arrowUp from "assets/images/ifg/arrow-up.png";
import topLoading from "assets/images/ifg/top-loading.png";
import leftLine1 from "assets/images/ifg/left-line1.png";
import leftLine2 from "assets/images/ifg/left-line2.png";
import hand from "assets/images/ifg/hand.png";

import originals from "assets/images/ifg/originals/originals.png";
import originals1 from "assets/images/ifg/originals/originals1.png";
import originals2 from "assets/images/ifg/originals/originals2.png";
import originals3 from "assets/images/ifg/originals/originals3.png";
import originals4 from "assets/images/ifg/originals/originals4.png";
import originals5 from "assets/images/ifg/originals/originals5.png";
import originals6 from "assets/images/ifg/originals/originals6.png";
import slots from "assets/images/ifg/slots/slots.png";
import slots1 from "assets/images/ifg/slots/slots1.png";
import slots2 from "assets/images/ifg/slots/slots2.png";
import slots3 from "assets/images/ifg/slots/slots3.png";
import slots4 from "assets/images/ifg/slots/slots4.png";
import slots5 from "assets/images/ifg/slots/slots5.png";
import slots6 from "assets/images/ifg/slots/slots6.png";
import sports from "assets/images/ifg/sports/sports.png";
import sports1 from "assets/images/ifg/sports/sport1.png";
import sports2 from "assets/images/ifg/sports/sport2.png";
import sports3 from "assets/images/ifg/sports/sport3.png";
import sports4 from "assets/images/ifg/sports/sport4.png";
import sports5 from "assets/images/ifg/sports/sport5.png";
import sports6 from "assets/images/ifg/sports/sport6.png";
import live from "assets/images/ifg/live/live.png";
import live1 from "assets/images/ifg/live/live1.png";
import live2 from "assets/images/ifg/live/live2.png";
import live3 from "assets/images/ifg/live/live3.png";
import live4 from "assets/images/ifg/live/live4.png";
import live5 from "assets/images/ifg/live/live5.png";
import live6 from "assets/images/ifg/live/live6.png";
import pvp from "assets/images/ifg/pvp/pvp.png";
import pvp1 from "assets/images/ifg/pvp/pvp1.png";
import pvp2 from "assets/images/ifg/pvp/pvp2.png";
import racing from "assets/images/ifg/race/racing.png";
import racing1 from "assets/images/ifg/race/racing1.png";
import racing2 from "assets/images/ifg/race/racing2.png";

import rightLine1 from "assets/images/ifg/right-line1.png";
import rightLine2 from "assets/images/ifg/right-line2.png";
import hand1 from "assets/images/ifg/hand1.png";
import gameSlots1 from "assets/images/ifg/games/slots/slots1.png";
import gameSlots2 from "assets/images/ifg/games/slots/slots2.png";
import gameSlots3 from "assets/images/ifg/games/slots/slots3.png";
import gameSlots4 from "assets/images/ifg/games/slots/slots4.png";
import gameSlots5 from "assets/images/ifg/games/slots/slots5.png";
import gameSlots6 from "assets/images/ifg/games/slots/slots6.png";
import gameSlots7 from "assets/images/ifg/games/slots/slots7.png";
import gameSlots8 from "assets/images/ifg/games/slots/slots8.png";
import gameSlots9 from "assets/images/ifg/games/slots/slots9.png";
import gameSlots10 from "assets/images/ifg/games/slots/slots10.png";
import gameSlots11 from "assets/images/ifg/games/slots/slots11.png";
import gameSlots12 from "assets/images/ifg/games/slots/slots12.png";
import gameSlots13 from "assets/images/ifg/games/slots/slots13.png";
import gameSlots14 from "assets/images/ifg/games/slots/slots14.png";
import gameCasino1 from "assets/images/ifg/games/casino/casino1.png";
import gameCasino2 from "assets/images/ifg/games/casino/casino2.png";
import gameCasino3 from "assets/images/ifg/games/casino/casino3.png";
import gameCasino4 from "assets/images/ifg/games/casino/casino4.png";
import gameCasino5 from "assets/images/ifg/games/casino/casino5.png";
import gameCasino6 from "assets/images/ifg/games/casino/casino6.png";
import more from "assets/images/ifg/more.png";
import websitStyle from "assets/images/ifg/websit-style-bgi.png";
import websitStyle1 from "assets/images/ifg/websit-style-bgi1.png";
import websitStyle2 from "assets/images/ifg/websit-style-bgi2.png";
import websitStyle3 from "assets/images/ifg/websit-style-bgi3.png";
import websitStyleMobile from "assets/images/ifg/websit-style-bgi-mobile.png";
import websitStyleMobile1 from "assets/images/ifg/websit-style-bgi-mobile1.png";
import websitStyleMobile2 from "assets/images/ifg/websit-style-bgi-mobile2.png";
import websitStyleMobile3 from "assets/images/ifg/websit-style-bgi-mobile3.png";
import ifgBgi from "assets/images/ifg/ifg-bgi.png";
import ifgBgiMobile from "assets/images/ifg/ifg-bgi-mobile.png";
import btnPre from "assets/images/ifg/btn-pre.png";
import btnNext from "assets/images/ifg/btn-next.png";
import star from "assets/images/ifg/star.png";
import touch from "assets/images/ifg/touch.png";
import avatar1 from "assets/images/ifg/avatar/avatar1.png";
import avatar2 from "assets/images/ifg/avatar/avatar2.png";
import avatar3 from "assets/images/ifg/avatar/avatar3.png";
import coin from "assets/images/ifg/coin.png";
import coin2 from "assets/images/ifg/coin2.png";
import bottomLine from "assets/images/ifg/bottom-line.png";
import overViewBgi from "assets/images/ifg/over-view-bgi.png";
import overViewBgiMobile from "assets/images/ifg/over-view-bgi-mobile.png";
import rightLine3 from "assets/images/ifg/right-line-3.png";
import add from "assets/images/ifg/add.png";
import arrowDown2 from "assets/images/ifg/arrow-down-2.png";
import arrowUp2 from "assets/images/ifg/arrow-up-2.png";
import key1 from "assets/images/ifg/keys/key1.png";
import key2 from "assets/images/ifg/keys/key2.png";
import key3 from "assets/images/ifg/keys/key3.png";
import key4 from "assets/images/ifg/keys/key4.png";
import key5 from "assets/images/ifg/keys/key5.png";
import key6 from "assets/images/ifg/keys/key6.png";
import key7 from "assets/images/ifg/keys/key7.png";
import key8 from "assets/images/ifg/keys/key8.png";
import key9 from "assets/images/ifg/keys/key9.png";
import leftLine3 from "assets/images/ifg/left-line-3.png";
import rightLine4 from "assets/images/ifg/right-line-4.png";
import connectBgi from "assets/images/ifg/connect-mobile-bgi.png";
import connectBgiPc from "assets/images/ifg/connect-pc-bgi.png";
import city from "assets/images/ifg/city.png";
import arrowDownGreen from "assets/images/ifg/arrow-down-green.png";
import close from "assets/images/ifg/close.png";

const Ifg = () => {
    const [isMobile, setIsMobile] = useState(false);

    const [pageType, setPageType] = useState<"home" | "requestDemo" | "contact">("home");

    const [openKey, setOpenKey] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const originalsList = {
        pc: [originals1, originals2, originals3, originals4, originals5, originals6],
        mobile: [originals1, originals2, originals3, originals4],
    };
    const slotsList = {
        pc: [slots1, slots2, slots3, slots4, slots5, slots6],
        mobile: [slots1, slots2, slots3, slots4],
    };
    const sportsList = {
        pc: [
            {img: sports1, title: "Cricket"},
            {img: sports2, title: "Soccer"},
            {img: sports3, title: "FIFA"},
            {img: sports4, title: "Esports"},
            {img: sports5, title: "Basketball"},
            {img: sports6, title: "Tennis"},
        ],
        mobile: [
            {img: sports1, title: "Cricket"},
            {img: sports2, title: "Soccer"},
            {img: sports3, title: "FIFA"},
            {img: sports4, title: "Esports"},
        ],
    };
    const liveList = {
        pc: [live1, live2, live3, live4, live5, live6],
        mobile: [live1, live2, live3, live4],
    };
    const gameSlotsList = [
        gameSlots1,
        gameSlots2,
        gameSlots3,
        gameSlots4,
        gameSlots5,
        gameSlots6,
        gameSlots7,
        gameSlots8,
        gameSlots9,
        gameSlots10,
        gameSlots11,
        gameSlots12,
        gameSlots13,
        gameSlots14,
    ];
    const gameCasinoList = [gameCasino1, gameCasino2, gameCasino3, gameCasino4, gameCasino5, gameCasino6];
    const userList = [
        {
            avatar: avatar1,
            name: "Playing.io",
            audit: "",
            desc1: "InfiGaming has been a huge step up for us!",
            desc2: "We've browsed through numerous websites like this, but none offer the range of languages and currencies that infiGaming does. We find it incredibly convenient.",
        },
        {
            avatar: avatar2,
            name: "Africa365",
            audit: "",
            desc1: "We've been able to modify various game parameters to suit our needs, something we've been searching for in a platform.",
            desc2: "We wanted a service that could customize website appearance and gameplay according to our ideas, and we found exactly that with infiGaming.",
        },
        {
            avatar: avatar3,
            name: "Boxbet.com",
            audit: "",
            desc1: "Their products are incredibly user-friendly",
            desc2: "We've been troubled by multilingual and payment issues on gaming websites for a while. infiGaming was exactly what we were looking for.",
        },
    ];
    const keyList = [
        {
            img: key1,
            name: "Customization",
            desc: "The interface can be customized according to the Figma files provided by the customer, or Figma design services can be provided according to the customer's requirements to achieve a personalized interface.",
        },
        {
            img: key2,
            name: "Operation Analysis",
            desc: "Operations analysts with more than 20 years of experience in chess and card games and more than 10 years of experience in the gaming industry provide free data analysis and product analysis consulting services.",
        },
        {
            img: key3,
            name: "Customer Service",
            desc: "We provide risk control and customer service training services. In the early stage, experts can be dispatched to lead the team to assist with operations.",
        },
        {
            img: key4,
            name: "Payment Center",
            desc: "Highly reliable self-developed cryptocurrency payment system; global currency payment center with flexible routing; supports user-specified payment channel access",
        },
        {
            img: key5,
            name: "Data Center",
            desc: "Data analysis and mining middle platform using Google BQ data warehouse + Data Studio technology to support all-round multi-dimensional data statistics and analysis",
        },
        {
            img: key6,
            name: "Financial Management",
            desc: "Provide complete and reliable data statistical reports for customers' financial settlements, and can also customize financial statements according to customer requirements",
        },
        {
            img: key7,
            name: "One-click Deployment",
            desc: "Automatically deploy all related services and provide default configurations according to country and region",
        },
        {
            img: key8,
            name: "Game Development",
            desc: "Develop designated local or personalized games for customers to enhance competitiveness",
        },
        {
            img: key9,
            name: "License Application",
            desc: "Provide free license application consultation and assistance services",
        },
    ];
    const domScrollIntoView = (dom: string) => {
        setPageType("home");
        setShowMobileMenu(false);
        setTimeout(() => {
            const $dom = document.querySelector(dom);
            if ($dom) {
                const offset = 150;
                const elementPosition = $dom.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }, 100);
    };

    const menuList = [
        {
            title: "What We Do",
            children: [
                {
                    title: "Main site",
                    clickHandler: () => {
                        window.open("https://www.infigaming.live/");
                    },
                },
                {
                    title: "Game R&D",
                    clickHandler: () => {
                        domScrollIntoView(".AbundantGameVarietiesAndContent");
                    },
                },
                {
                    title: "Business cooperation",
                    clickHandler: () => {
                        domScrollIntoView(".GameSuppliers120");
                    },
                },
            ],
        },
        {
            title: "The Platform",
            children: [
                {
                    title: "Customization",
                    clickHandler: () => {
                        domScrollIntoView(".HighlyCustomizablePersonalizedWebsite");
                    },
                },
                {
                    title: "Customer reviews",
                    clickHandler: () => {
                        domScrollIntoView(".CustomerReviews");
                    },
                },
            ],
        },
        {
            title: "Who We Are",
            children: [
                {
                    title: "About us",
                    clickHandler: () => {
                        domScrollIntoView(".CompanyOverview");
                    },
                },
                {
                    title: "Why us",
                    clickHandler: () => {
                        domScrollIntoView(".KeyAdvantagesOfChoosingUs");
                    },
                },
            ],
        },
        {
            title: "Contact",
            outClick: () => {
                setShowMobileMenu(false);
                setPageType("contact");
            },
        },
    ];

    const handleResize = useCallback(() => {
        const innerWidth = window.innerWidth;
        if (innerWidth <= 640 && !isMobile) {
            setIsMobile(true);
        }
        if (innerWidth > 640 && isMobile) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        handleResize();
        if (showMobileMenu) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        window.addEventListener("resize", handleResize);
        window.addEventListener("load", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
        };
    }, [showMobileMenu, handleResize]);

    const websitStylePCList = [websitStyle, websitStyle1, websitStyle2, websitStyle3];
    const websitStyleMobileList = [websitStyleMobile, websitStyleMobile1, websitStyleMobile2, websitStyleMobile3];

    return (
        <div className="pb-20">
            <div className={`w-full bg-[#0B091B] sticky left-0 top-0 z-20 ${isMobile ? "h-[3.75rem]" : "h-20"}`}>
                <div className={`h-full ${isMobile ? "w-full" : "w-[1280px] mx-auto"} flex items-center justify-between`}>
                    {isMobile ? (
                        <>
                            <img
                                src={logo}
                                alt=""
                                className="h-1/2 object-contain ml-3 cursor-pointer"
                                onClick={() => {
                                    setPageType("home");
                                }}
                            />
                            <div
                                className="w-[3.75rem] h-[3.75rem] bg-[#6149F7] flex items-center justify-center"
                                onClick={() => {
                                    setShowMobileMenu(true);
                                }}>
                                <img src={menu} alt="" className="w-12 h-12" />
                            </div>
                        </>
                    ) : (
                        <>
                            <img
                                src={logo}
                                alt=""
                                className="h-8 object-contain flex-shrink-0 cursor-pointer"
                                onClick={() => {
                                    setPageType("home");
                                }}
                            />
                            <div className="flex-1 flex items-center pl-10">
                                {menuList.map((v, key) => {
                                    return (
                                        <div
                                            key={key}
                                            className="flex items-center cursor-pointer relative h-12 pl-4 pr-8"
                                            onMouseEnter={() => {
                                                setActiveMenu(v.title);
                                            }}
                                            onClick={() => {
                                                v.outClick && v.outClick();
                                            }}>
                                            <div className="text-lg font-semibold">{v.title}</div>
                                            {v.children && <img src={arrowDown} alt="" className="w-4 h-4 ml-1" />}
                                            {activeMenu === v.title && (
                                                <div
                                                    className="w-full absolute left-0 top-0 -ml-[1px] bg-[#0B091B]"
                                                    onMouseLeave={() => {
                                                        setActiveMenu("");
                                                    }}>
                                                    <div className="flex items-center h-12 pl-4" style={{border: "1px solid #343341"}}>
                                                        <div className="text-lg font-semibold text-[#4ADD85]">{v.title}</div>
                                                        {v.children && <img src={arrowDownGreen} alt="" className="w-4 h-4 ml-1" />}
                                                    </div>
                                                    {v.children &&
                                                        v.children.map((v1, key1) => {
                                                            return (
                                                                <div
                                                                    key={key1}
                                                                    className="flex items-center h-12 pl-4 text-[#b6b5ba] hover:text-[#4ADD85]"
                                                                    style={{border: "1px solid #343341", borderTop: "none"}}>
                                                                    <div
                                                                        className="text-sm font-semibold w-full h-full flex items-center"
                                                                        onClick={() => {
                                                                            v1.clickHandler && v1.clickHandler();
                                                                        }}>
                                                                        {v1.title}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex-shrink-0 flex">
                                <div
                                    className="bg-[#6149F7] flex items-center px-4 py-3 cursor-pointer"
                                    onClick={() => {
                                        setPageType("requestDemo");
                                    }}>
                                    <div className="text-base font-semibold">Request a Demo</div>
                                    <img src={arrowUp} alt="" className="w-4 h-4 ml-1" />
                                </div>
                                {/* <div className="ml-6 flex items-center bg-[#343242] px-2 py-1 cursor-pointer">
                                    <div>En</div>
                                    <img src={arrowDown1} alt="" className="ml-1 w-3 h-3" />
                                </div> */}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {pageType === "home" && (
                <>
                    <div className="w-full relative">
                        <img src={isMobile ? topBgiMobile : topBgi} alt="" className="w-full object-contain block" />
                        <div className={`w-full h-full absolute left-0 top-0 ${isMobile ? "pt-10" : "pt-[5%]"}`}>
                            <img src={topLoading} alt="" className="w-7 h-9 mx-auto block" />
                            <div className={`${isMobile ? "w-[334px]" : "w-[700px]"} mx-auto`}>
                                <Swiper
                                    key="top-swiper"
                                    loop={true}
                                    className="w-full top-swiper"
                                    style={{zIndex: 0}}
                                    modules={[Navigation, Autoplay, Pagination]}
                                    autoplay={{delay: 5000, disableOnInteraction: false}}
                                    pagination={{
                                        clickable: true,
                                    }}>
                                    <SwiperSlide key={1}>
                                        <div className={`${isMobile ? "w-[334px] text-[28px]" : "w-[700px] text-[40px]"} mx-auto font-bold text-center mt-10`}>
                                            Professional Crypto Gambling Game Solutions
                                        </div>
                                        <div
                                            className={`${
                                                isMobile ? "mt-4 text-sm w-[334px]" : "mt-[2%] text-base w-[558px]"
                                            } font-medium text-[#A7A7A7] text-center mx-auto`}>
                                            We have a professional research and development team that provides a wide variety of gambling game solutions.
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide key={2}>
                                        <div className={`${isMobile ? "w-[334px] text-[28px]" : "w-[700px] text-[40px]"} mx-auto font-bold text-center mt-10`}>
                                            Fully Developed Eco-System
                                        </div>
                                        <div
                                            className={`${
                                                isMobile ? "mt-4 text-sm w-[334px]" : "mt-[2%] text-base w-[558px]"
                                            } font-medium text-[#A7A7A7] text-center mx-auto`}>
                                            Our platform has a complete system ecosystem, reliable system architecture, supports multi-currency transactions
                                            including crypto and mainstream fiat currencies, and provides globalized operations.
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className={`${isMobile ? "w-full" : "w-[1280px] mx-auto"} flex items-center`}>
                        <img src={isMobile ? leftLine2 : leftLine1} alt="" className={`${isMobile ? "w-9" : "w-9"} object-contain flex-shrink-0`} />
                        <div className={`flex-1 ${isMobile ? "pr-3" : ""}`}>
                            <div className={`${isMobile ? "pl-3" : "pl-16"}`}>
                                <div className={`relative ${isMobile ? "text-[24px] pl-8" : "text-[32px]"} AbundantGameVarietiesAndContent`}>
                                    Abundant game varieties and content
                                    <img src={hand} alt="" className={`w-14 object-contain absolute ${isMobile ? "-left-9 -top-4" : "-left-16 -top-3"}`} />
                                </div>
                                <div className={`w-full grid ${isMobile ? "mt-6 grid-cols-1" : "mt-16 grid-cols-2"}`}>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(129deg, #0B091B 35.38%, #6149F7 129.31%)"}}>
                                        <img src={originals} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">Originals Games</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            We have nearly 20 proprietary gambling games with fairness safeguards.
                                        </div>
                                        <div className={`w-full mt-8 grid grid-rows-1 gap-2 ${isMobile ? "grid-cols-4" : "grid-cols-6"}`}>
                                            {isMobile ? (
                                                <>
                                                    {originalsList.mobile.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {originalsList.pc.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(306deg, #0B091B 47.78%, #E949F7 127.07%)"}}>
                                        <img src={slots} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">Slots Games</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            Our platform presents an extensive collection of almost a thousand popular slot games, brought to you by a wide
                                            range of leading suppliers. This ensures a variety of choices to cater to all your gaming preferences.
                                        </div>
                                        <div className={`w-full mt-8 grid grid-rows-1 gap-2 ${isMobile ? "grid-cols-4" : "grid-cols-6"}`}>
                                            {isMobile ? (
                                                <>
                                                    {slotsList.mobile.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {slotsList.pc.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(249deg, #0B091B 46.66%, #49F7D8 163.65%)"}}>
                                        <img src={sports} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">Sports Event Betting</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            We offer a variety of sports betting products, including live betting and professional data analytics.
                                        </div>
                                        <div className={`w-full mt-8 grid grid-rows-1 gap-2 ${isMobile ? "grid-cols-4" : "grid-cols-6"}`}>
                                            {isMobile ? (
                                                <>
                                                    {sportsList.mobile.map((v, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="w-full flex flex-col items-center justify-center py-3"
                                                                style={{background: "rgba(255, 255, 255, 0.10)"}}>
                                                                <img src={v.img} alt="" className="w-7 h-7 object-contain" />
                                                                <div className="mt-1 text-xs font-medium">{v.title}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {sportsList.pc.map((v, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="w-full flex flex-col items-center justify-center py-3"
                                                                style={{background: "rgba(255, 255, 255, 0.10)"}}>
                                                                <img src={v.img} alt="" className="w-7 h-7 object-contain" />
                                                                <div className="mt-1 text-xs font-medium">{v.title}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(70deg, #0B091B 59.11%, #F75E49 122.22%)"}}>
                                        <img src={live} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">Live Casino</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            Live dealer casinos offer players the authentic atmosphere of traditional gaming experiences.
                                        </div>
                                        <div className={`w-full mt-8 grid grid-rows-1 gap-2 ${isMobile ? "grid-cols-4" : "grid-cols-6"}`}>
                                            {isMobile ? (
                                                <>
                                                    {liveList.mobile.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    {liveList.pc.map((v, key) => {
                                                        return <img key={key} src={v} alt="" />;
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(320deg, #0B091B 44.88%, #2DF358 175.36%)"}}>
                                        <img src={pvp} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">PVP Games</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            Our platform offers over 100+ mahjong games and Texas pokers.
                                        </div>
                                        <div className="w-full mt-8">
                                            <img src={isMobile ? pvp2 : pvp1} alt="" className="w-full object-contain" />
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col ${isMobile ? "p-4" : "p-6"}`}
                                        style={{border: "1px solid #54535F", background: "linear-gradient(243deg, #0B091B 45.59%, #FFFA81 162.45%)"}}>
                                        <img src={racing} alt="" className="w-7 h-7" />
                                        <div className="mt-2 text-lg font-semibold">eHorse Racing</div>
                                        <div className={`text-base opacity-70 mt-6 ${isMobile ? "" : "h-16"}`}>
                                            Enjoy round-the-clock access to numerous e-horse racing events.
                                        </div>
                                        <div className={`w-full ${isMobile ? "mt-8" : "mt-0"}`}>
                                            <img src={isMobile ? racing2 : racing1} alt="" className="w-full object-contain" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${isMobile ? "w-full" : "w-[1280px] mx-auto"} flex items-center`}
                        style={{borderTop: "1px solid rgba(255, 255, 255, 0.30)", borderBottom: "1px solid rgba(255, 255, 255, 0.30)"}}>
                        <div className={`flex-1 ${isMobile ? "px-3" : "pl-10 pr-24"}`}>
                            <div className={`${isMobile ? "text-[24px]" : "text-[32px]"} GameSuppliers120`}>
                                120+ game suppliers and &nbsp;
                                {!isMobile && (
                                    <>
                                        <br />
                                    </>
                                )}
                                12000+ games in our platform
                            </div>
                            <div className="mt-6 text-base font-medium">
                                Our platform is connected with a wide array of game suppliers, and we're also equipped to facilitate access to other providers
                                upon request.
                            </div>
                            <div className={`w-full mt-10 flex ${isMobile ? "flex-col" : "flex-row justify-between"}`}>
                                <div>
                                    <div>Slots Game Providers</div>
                                    <div className="h-1 w-full bg-[#6149F7] mt-1"></div>
                                    <div className={`w-full mt-3 grid ${isMobile ? "grid-cols-4" : "grid-cols-7"} gap-2`}>
                                        {gameSlotsList.map((v, key) => {
                                            return <img key={key} src={v} alt="" className="w-[85px] object-contain" />;
                                        })}
                                    </div>
                                    {!isMobile && <img src={more} alt="" className="mt-3 w-20 object-contain block" />}
                                </div>
                                <div className={`${isMobile ? "mt-3" : ""}`}>
                                    <div>Livecasino Game Providers</div>
                                    <div className="h-1 w-full bg-[#6149F7] mt-1"></div>
                                    <div className={`w-full mt-3 grid ${isMobile ? "grid-cols-4" : "grid-cols-3"} gap-2`}>
                                        {gameCasinoList.map((v, key) => {
                                            return <img key={key} src={v} alt="" className="object-contain w-[85px]" />;
                                        })}
                                    </div>
                                    {!isMobile && <img src={more} alt="" className="mt-3 w-20 object-contain block" />}
                                </div>
                            </div>
                        </div>
                        <div className={`flex-shrink-0 relative ${isMobile ? "w-9" : "w-32"}`}>
                            <img src={isMobile ? rightLine2 : rightLine1} alt="" className="w-full object-contain" />
                            <img src={hand1} alt="" className={`w-14 h-12 object-contain absolute ${isMobile ? "-left-3 top-10" : "-left-7 top-20"}`} />
                        </div>
                    </div>
                    <div>
                        <div className={`w-full my-6 ${isMobile ? "" : "bg-[#6149F6]"} relative HighlyCustomizablePersonalizedWebsite`}>
                            {isMobile && (
                                <>
                                    <div className="w-full px-7 flex items-center">
                                        <img src={star} alt="" className="w-6" />
                                        <div className="px-1 flex-1 text-center text-2xl">Highly customizable personalized website</div>
                                        <img src={star} alt="" className="w-6" />
                                    </div>
                                    <div className="w-full mt-4 bg-[#6149F6] relative">
                                        <img src={ifgBgiMobile} alt="" className="w-full" />
                                        <div className="w-full absolute left-0 top-0 pt-6">
                                            <Swiper
                                                key="high-swiper-mobile"
                                                loop={true}
                                                className="w-full relative highly-swiper highly-swiper-mobile"
                                                style={{zIndex: 2}}
                                                modules={[Navigation, Autoplay, Pagination]}
                                                autoplay={{delay: 5000, disableOnInteraction: false}}
                                                navigation={{nextEl: ".btn-next-mobile", prevEl: ".btn-pre-mobile"}}
                                                pagination={{
                                                    clickable: true,
                                                }}>
                                                {websitStyleMobileList.map((v, key) => {
                                                    return (
                                                        <SwiperSlide key={key}>
                                                            <img src={v} className="w-full" alt="" />
                                                        </SwiperSlide>
                                                    );
                                                })}
                                            </Swiper>
                                        </div>
                                        <img src={btnPre} alt="" className="w-7 h-7 absolute left-4 bottom-2 cursor-pointer btn-pre-mobile z-30" />
                                        <img src={btnNext} alt="" className="w-7 h-7 absolute right-4 bottom-2 cursor-pointer btn-next-mobile z-30" />
                                    </div>
                                </>
                            )}
                            {!isMobile && (
                                <>
                                    <img src={ifgBgi} alt="" className="w-full" />
                                    <div className="w-full absolute left-0 top-0 pt-6">
                                        <div className={`${isMobile ? "w-full" : "w-[1280px] mx-auto"} flex items-center justify-center`}>
                                            <img src={star} alt="" className="w-7" />
                                            <div className="text-3xl px-5">Highly customizable personalized website</div>
                                            <img src={star} alt="" className="w-7" />
                                        </div>
                                        <div className="w-1/2 mx-auto mt-4 relative">
                                            <Swiper
                                                key="high-swiper-pc"
                                                loop={true}
                                                className="w-full relative highly-swiper"
                                                style={{zIndex: 2}}
                                                modules={[Navigation, Autoplay, Pagination]}
                                                autoplay={{delay: 5000, disableOnInteraction: false}}
                                                navigation={{nextEl: ".btn-next", prevEl: ".btn-pre"}}
                                                pagination={{
                                                    clickable: true,
                                                }}>
                                                {websitStylePCList.map((v, key) => {
                                                    return (
                                                        <SwiperSlide key={key}>
                                                            <img src={v} alt="" className="w-full block" />
                                                        </SwiperSlide>
                                                    );
                                                })}
                                            </Swiper>
                                            <img
                                                src={btnPre}
                                                alt=""
                                                className="w-8 h-8 absolute -left-12 cursor-pointer btn-pre"
                                                style={{top: "50%", transform: "translateY(-50%)"}}
                                            />
                                            <img
                                                src={btnNext}
                                                alt=""
                                                className="w-8 h-8 absolute -right-12 cursor-pointer btn-next"
                                                style={{top: "50%", transform: "translateY(-50%)"}}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={`text-center text-base font-medium ${isMobile ? "w-full px-3" : "w-[700px] mx-auto"}`}>
                            We provide profoundly customizable gambling games, including different languages, currencies, game types, art styles, and win/loss
                            parameters.
                        </div>
                    </div>
                    <div
                        className={`mt-10 ${isMobile ? "w-full pt-4" : "w-[1280px] mx-auto pt-10"} relative`}
                        style={{borderTop: "1px solid rgba(255, 255, 255, 0.30)"}}>
                        <div className="flex items-center">
                            <img src={touch} alt="" className="w-9 h-9" />
                            <div className="text-[32px] pl-4 CustomerReviews">Customer reviews</div>
                        </div>
                        <div className={`mt-4 grid ${isMobile ? "grid-cols-1 px-3" : "grid-cols-3"} gap-3`}>
                            {userList.map((v, key) => {
                                return (
                                    <div key={key} className="pt-4 pb-6" style={{border: "1px solid #54535F", borderRadius: "40px 0 0 0"}}>
                                        <div className="w-full h-14 flex px-4">
                                            <img src={v.avatar} alt="" className="h-full object-contain" />
                                            <div className="pl-5 flex flex-col justify-center">
                                                <div className="text-lg font-semibold">{v.name}</div>
                                                {v.audit && <div className="text-base font-medium text-[#9d9da3]">{v.audit}</div>}
                                            </div>
                                        </div>
                                        <div className="w-full h-[1px] bg-[#54535F] my-4"></div>
                                        <div className="px-4">
                                            <div className="text-lg font-semibold">{v.desc1}</div>
                                            <div className="text-base font-medium text-[#9d9da3] mt-3">{v.desc2}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <img src={coin2} alt="" className={`w-9 h-9 absolute ${isMobile ? "right-0" : "-right-3"} bottom-1`} />
                        <img src={coin} alt="" className="w-9 h-9 absolute right-1 -bottom-5" />
                    </div>
                    <div className={`mt-10 ${isMobile ? "w-full pt-4" : "w-[1280px] mx-auto"}`}>
                        <img src={bottomLine} alt="" className="w-full mt-10" />
                    </div>
                    <div className="w-full my-10 relative">
                        <img src={isMobile ? overViewBgiMobile : overViewBgi} alt="" className="w-full object-contain block" />
                        <div className="w-full h-full flex flex-col items-center justify-center absolute left-0 top-0">
                            <div className="text-[32px] CompanyOverview">Company overview</div>
                            <div className={`text-lg font-medium text-center ${isMobile ? "w-full px-3 my-3" : "w-[890px] my-6"}`}>
                                infiGaming was established in 2022 by a number of experienced industry professionals with over 30 years combined experience in
                                the online betting and gaming industry for both the B2B and B2C operations. Our mission is simply to utilise our expertise in
                                the industry and deployment along with our global network, to supply our clients with a range of compelling and innovative
                                content to supply to its customers.
                            </div>
                            <img src={logo} alt="" className="h-7 object-contain" />
                        </div>
                    </div>
                    <div
                        className={`mt-10 ${isMobile ? "w-full pt-4" : "w-[1280px] mx-auto"} KeyAdvantagesOfChoosingUs`}
                        style={{borderTop: "1px solid rgba(255, 255, 255, 0.30)", borderBottom: "1px solid rgba(255, 255, 255, 0.30)"}}>
                        {isMobile ? (
                            <div className="w-full flex items-center">
                                <div className="flex-1 px-3">
                                    <div className="text-[24px]">Key advantages of choosing us</div>
                                    <div className="mt-4 w-full flex flex-col gap-1">
                                        {keyList.map((v, key) => {
                                            return (
                                                <div key={key} className="w-full h-[88px] bg-[#151323] flex  items-center pl-3 relative">
                                                    <img src={v.img} alt="" className="w-14 h-14" />
                                                    <div className="pl-2 text-sm">
                                                        <div>{v.name}</div>
                                                        <div
                                                            className="mt-1 flex items-center"
                                                            onClick={() => {
                                                                setOpenKey(v.name);
                                                            }}>
                                                            <div className="text-[#4ADD85]">Show More</div>
                                                            <img src={arrowDown2} alt="" className="ml-1 w-4 h-4" />
                                                        </div>
                                                    </div>
                                                    {openKey === v.name && (
                                                        <div className="w-full h-[364px] bg-[#151323] flex flex-col items-center justify-center absolute z-10 left-0 top-0">
                                                            <img src={v.img} alt="" className="w-14 h-14" />
                                                            <div className="mt-3 text-base">{v.name}</div>
                                                            <div className="mt-4 text-sm text-[#b9b8bd] text-center">{v.desc}</div>
                                                            <div
                                                                className="mt-10 flex items-center"
                                                                onClick={() => {
                                                                    setOpenKey("");
                                                                }}>
                                                                <div className="text-[#4ADD85]">Show More</div>
                                                                <img src={arrowUp2} alt="" className="ml-1 w-4 h-4" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className={`flex-shrink-0 relative w-16`}>
                                    <img src={rightLine3} alt="" className="w-full object-contain" />
                                    <img src={add} alt="" className={`w-9 h-9 object-contain absolute -left-3 top-3`} />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex items-center">
                                <img src={leftLine3} alt="" className="w-5 object-contain flex-shrink-0" />
                                <div className="flex-1 h-full pl-5 pr-10">
                                    <div className="w-full grid grid-cols-3 grid-rows-3 gap-3 relative">
                                        {keyList.map((v, key) => {
                                            return (
                                                <div key={key} className="w-full h-40 bg-[#151323] flex flex-col items-center justify-center relative">
                                                    <img src={v.img} alt="" className="w-14 h-14" />
                                                    <div className="mt-1">{v.name}</div>
                                                    <div
                                                        className="mt-1 flex items-center cursor-pointer"
                                                        onClick={() => {
                                                            setOpenKey(v.name);
                                                        }}>
                                                        <div className="text-[#4ADD85]">Show More</div>
                                                        <img src={arrowDown2} alt="" className="ml-1 w-4 h-4" />
                                                    </div>

                                                    {openKey === v.name && (
                                                        <div className="w-full h-[330px] bg-[#151323] flex flex-col items-center justify-center absolute z-10 left-0 top-0">
                                                            <img src={v.img} alt="" className="w-14 h-14" />
                                                            <div className="mt-3 text-base">{v.name}</div>
                                                            <div className="mt-4 text-sm text-[#b9b8bd] text-center px-3">{v.desc}</div>
                                                            <div
                                                                className="mt-10 flex items-center cursor-pointer"
                                                                onClick={() => {
                                                                    setOpenKey("");
                                                                }}>
                                                                <div className="text-[#4ADD85]">Show More</div>
                                                                <img src={arrowUp2} alt="" className="ml-1 w-4 h-4" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <img src={rightLine4} alt="" className="w-32 object-contain flex-shrink-0" />
                            </div>
                        )}
                    </div>
                    <div className={`${isMobile ? "w-full pt-4" : "w-[1280px] mx-auto mt-10"}`}>
                        <img src={bottomLine} alt="" className="w-full mt-10" />
                    </div>
                    {isMobile ? (
                        <>
                            <div className="mt-6 px-3">
                                <div className="w-full relative">
                                    <img src={connectBgi} alt="" className="w-full object-contain block" />
                                    <div className="w-full h-full flex flex-col justify-center pl-4 absolute left-0 top-0">
                                        <div className="text-[32px]">Contact US</div>
                                        <div className="text-lg mt-3">
                                            Get in touch to discover how our custom-built solutions can help propel your business to the next level.
                                        </div>
                                        <div className="mt-4 px-3">
                                            <div
                                                className="w-full h-12 bg-[#6149F7] flex items-center justify-center"
                                                onClick={() => {
                                                    setPageType("requestDemo");
                                                }}>
                                                <div className="text-base font-semibold">Request a Demo</div>
                                                <img src={arrowUp} alt="" className="w-4 h-4 ml-1" />
                                            </div>
                                            <div
                                                className="mt-3 w-full h-12 bg-[#6149F7] flex items-center justify-center"
                                                onClick={() => {
                                                    setPageType("contact");
                                                }}>
                                                <div className="text-base font-semibold">Contact Us</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-12 h-12 mt-4 mx-auto bg-[#151324] flex flex-col items-center justify-center cursor-pointer"
                                onClick={() => {
                                    window.scrollTo({left: 0, top: 0, behavior: "smooth"});
                                }}>
                                <img src={arrowUp2} alt="" className="w-4 h-4" />
                                <div className="text-sm text-[#4ADD85]">Top</div>
                            </div>
                        </>
                    ) : (
                        <div className="w-[1280px] mx-auto mt-10 relative">
                            <div className="w-full relative">
                                <img src={connectBgiPc} alt="" className="w-full object-contain block" />
                                <div className="w-full h-full flex items-center absolute left-0 top-0">
                                    <img src={city} alt="" className="h-full object-contain flex-shrink-0" />
                                    <div className="flex-1">
                                        <div className="text-[32px]">Contact US</div>
                                        <div className="mt-10 text-lg">
                                            Get in touch to discover how our custom-built solutions can help propel your business to the next level.
                                        </div>
                                        <div className="mt-20 flex gap-4">
                                            <div
                                                className="w-[180px] h-12 bg-[#6149F7] flex items-center justify-center cursor-pointer"
                                                onClick={() => {
                                                    setPageType("requestDemo");
                                                }}>
                                                <div className="text-base font-semibold">Request a Demo</div>
                                                <img src={arrowUp} alt="" className="w-4 h-4 ml-1" />
                                            </div>
                                            <div
                                                className="w-[180px] h-12 bg-[#6149F7] flex items-center justify-center cursor-pointer"
                                                onClick={() => {
                                                    setPageType("contact");
                                                }}>
                                                <div className="text-base font-semibold">Contact Us</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="w-12 h-12 mt-4 mx-auto bg-[#151324] flex flex-col items-center justify-center absolute -right-14 bottom-0 cursor-pointer"
                                onClick={() => {
                                    window.scrollTo({left: 0, top: 0, behavior: "smooth"});
                                }}>
                                <img src={arrowUp2} alt="" className="w-4 h-4" />
                                <div className="text-sm text-[#4ADD85]">Top</div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {showMobileMenu && (
                <div className="w-full h-full fixed left-0 top-0 z-30 bg-[#0B091B] flex flex-col">
                    <div className="h-[3.75rem] flex items-center justify-between flex-shrink-0">
                        <img
                            src={logo}
                            alt=""
                            className="h-1/2 object-contain ml-3"
                            onClick={() => {
                                setActiveMenu("");
                                setPageType("home");
                            }}
                        />
                        <div
                            className="w-[3.75rem] h-[3.75rem] bg-[#6149F7] flex items-center justify-center"
                            onClick={() => {
                                setShowMobileMenu(false);
                                setActiveMenu("");
                            }}>
                            <img src={close} alt="" className="w-6 h-6" />
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col pb-14 px-4">
                        <div className="flex-1 pt-6 flex flex-col items-center">
                            {menuList.map((v, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="mt-4"
                                        onClick={() => {
                                            setActiveMenu(v.title);
                                            v.outClick && v.outClick();
                                        }}>
                                        {activeMenu !== v.title ? (
                                            <div className="flex items-center justify-center cursor-pointer h-12 px-4">
                                                <div className="text-lg font-semibold">{v.title}</div>
                                                {v.children && <img src={arrowDown} alt="" className="w-4 h-4 ml-1" />}
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setActiveMenu("");
                                                }}>
                                                <div className="flex items-center justify-center h-12 px-4" style={{border: "1px solid #343341"}}>
                                                    <div className="text-lg font-semibold text-[#4ADD85]">{v.title}</div>
                                                    {v.children && <img src={arrowDownGreen} alt="" className="w-4 h-4 ml-1" />}
                                                </div>
                                                {v.children &&
                                                    v.children.map((v1, key1) => {
                                                        return (
                                                            <div
                                                                key={key1}
                                                                className="flex items-center justify-center h-12 px-4 text-[#b6b5ba] hover:text-[#4ADD85]"
                                                                style={{border: "1px solid #343341", borderTop: "none"}}>
                                                                <div
                                                                    className="text-sm font-semibold "
                                                                    onClick={() => {
                                                                        v1.clickHandler && v1.clickHandler();
                                                                    }}>
                                                                    {v1.title}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            {/* <div className="mt-4 flex items-center bg-[#343242] px-2 py-1">
                                <div>En</div>
                                <img src={arrowDown1} alt="" className="ml-1 w-3 h-3" />
                            </div> */}
                        </div>
                        <div
                            className="flex-shrink-0 w-full h-12 bg-[#6149F7] flex items-center justify-center"
                            onClick={() => {
                                setShowMobileMenu(false);
                                setPageType("requestDemo");
                            }}>
                            <div className="text-base font-semibold">Request a Demo</div>
                            <img src={arrowUp} alt="" className="w-4 h-4 ml-1" />
                        </div>
                    </div>
                </div>
            )}

            {pageType === "requestDemo" && <RequestDemo />}
            {pageType === "contact" && <Contact />}

            <ToastContainer position="top-left" autoClose={2000} hideProgressBar={true} theme="dark" />
        </div>
    );
};

export default Ifg;
