import {useEffect} from "react";

const Privacy = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const contentList = [
        {
            title: "1. Introduction",
            children: [
                "Under the guidelines of this Privacy Policy, the “Data Controller” is the company that determines the purpose and processing of personal data.",
                "InfiGaming Limited acts in its capacity of Data Controller in terms of the EU Regulation, thereby determining the purpose and processing of your personal data. For more information on the contact details of the Data Controller, please refer to Section 8.",
                "Please note that in using and/or browsing and/or visiting this website, you hereby agree to be bound by the guidelines of this Privacy Policy.",
            ],
        },
        {
            title: "2. Information collection",
            children: [
                "Should you ever need to use our contact form for general enquiries or to schedule a demo, you will be required to provide certain personal details including your name, email address and telephone number. Once you have done so, this data will be processed in order for us to provide you with the support you require, and to maintain accurate records for our database. This will also serve to improve our website and the users’ experience, and allow us to potentially enter into a business contract with you in the future.",
            ],
        },
        {
            title: "3. Information usage",
            children: [
                "Such information referred to in Section 2 will be used, processed and stored in order for us to:",
                "· Contact you with regards to any InfiGaming events, products or services you may be interested in.",
                "· Carry out certain profiling of you and your website activity in order to improve your customer experience, serve you relevant content and send you more targeted marketing and email communication.",
                "In the event of these occurrences, your personal information will be processed for the purposes of offering you products, services, or marketing opportunities that may be of interest to you. Should you wish to opt out of these at any time, you may follow the instructions included in our communication in order to unsubscribe from our mailing list.",
            ],
        },
        {
            title: "4. Information sharing and disclosure",
            children: [
                "Your personal information, as referred to in Section 2, will not be distributed to any third party outside the infiGaming website, with the exception of certain circumstances, such as:",
                "Legal compliance",
                "From time to time we may disclose your personal information to law enforcement, the government, or any authorised third parties to the extent that the law requires, in order to a) comply with any legal obligations, b) respond to any claims made against us, or c) respond to any requests regarding criminal investigations, alleged illegal activity, or any activity that may leave infiGaming, our users, or you open to legal liability.",
                "Under these circumstances, your personal information may be shared in order to protect our business and its legal rights.",
                "Third party service providers",
                "From time to time we may make use of third party service providers in order to offer certain website or payment-related services. These service providers may or may not rest within the borders of the European Economic Area (EEA).",
                "Rest assured that these providers will only ever have limited access to your personal and/or financial information, and are contractually bound to safeguard your details, and to only use them for the specific purposes for which they were disclosed, in association with the guidelines of this Privacy Policy.",
                "Should you wish to procure a complete list of the third-party service providers with access to your information, please contact us.",
                "Corporate affiliates",
                "From time to time we may share your information with any member of the InfiGaming group (including our holding company and any subsidiaries) for legal purposes as laid out in this Privacy Policy.",
                "Aggregated data",
                "From time to time it may become necessary for us to aggregate or combine certain user information in an anonymised manner for the purposes of compliance, analysis, marketing, advertising, profiling or similar.",
                "Business transfers",
                "Should infiGaming ever be involved in business transfers such as a merger, acquisition, sale of assets, bankruptcy, insolvency or similar, our assets, including any connection you may have with such transfers, may be sold, shared or transferred in the interests of the completion of the transaction. In the event of such a transfer occurring, we will notify you prior to your information being shared, and ultimately falling under the guidelines of a different privacy policy.",
            ],
        },
        {
            title: "5. Information subject rights",
            children: [
                "Note that under the General Data Protection Regulation, you have the right to access, update, move and delete certain personal information; additionally you also have the right to restrict the processing of your data in certain cases. Any queries you make regarding your data will be reviewed on a case-by-case basis, with the collection and processing of your data, the nature of the data itself, and any relevant legal or operational strictures being taken into account.",
                "In order to exercise your rights in this regard, please contact the Data Controller by sending an email to business@infigaming.com . Note that you may be required to verify your identity before any action can be taken. Also bear in mind that since these rights are a courtesy, and not absolute, we may refuse your request, or only be able to comply with certain parts of it.",
                "Inaccurate or incomplete information",
                "Should you notice any inaccuracies or omissions in your information, you have the right to ask InfiGaming to correct or update it.",
                "Access and portability",
                "Should you wish to access any personal data retained by InfiGaming, you have the right to request that this be sent to you in an easily readable format, or that it be sent to another third party, where possible.",
                "Retention and deletion",
                "Your personal information will be retained for as long as the contract between us exists, and for as long as it is needed for legal obligations. Once it no longer becomes necessary for us to retain your information, it will be deleted, provided there are no legal obligations that require we retain it for longer.",
                "Should it no longer be necessary for us to retain or process your data, you have the right to request that we delete it. Similarly should you withdraw your consent, object to our retention of your data, determine that it has been unlawfully processed or that erasure is legally required, you may request that your information be deleted.",
                "Please note, however, that certain exclusions to deletion do apply, such as in cases of processing being necessary for legal compliance or legal claims, or for exercising the right to freedom of expression and information.",
                "Withdrawal of consent and processing restrictions",
                "In the case of InfiGaming having requested your consent for the processing of your data, and there being no other legal considerations, you may withdraw your consent for processing by either changing your account settings or by sending an email to business@infigaming.com with specific instructions as to which consent you withdraw. Note that this withdrawal of consent will not affect the legalities of any data processing based on your consent, prior to its withdrawal.",
                "Further to this, certain laws may give you the right to limit our processing and management of your information, such as where a) you dispute the accuracy of your information, b) you determine the processing to be illegal, c) your information is no longer needed by InfiGaming, but you need it for the purposes of legal claims, or d) you have objected to the processing of your information (as per the following section) and this objection is pending verification based on whether the legal concerns of the Data Controller override your own.",
                "Processing objections",
                "Should it be in InfiGaming’s legal interests, you have the right to object to the processing of your information, however please note that we will continue to process your information should there be a legitimate interest to do so, or should we have compelling grounds to do so that do not conflict with your personal rights and freedoms.",
                "If you object to any direct marketing opportunities from InfiGaming, you may opt out of these via your account settings or via the unsubscribe instructions in our communications; you also have the right to object to any demographic profiling with respect to its direct marketing concerns.",
                "Complaints",
                "Please note that should you have any complaints about the Data Controller’s management of data processing activities, you are welcome to contact your respective Data Protection Authority. Please refer to Section 8 for the relevant details.",
            ],
        },
        {
            title: "",
            children: [
                "For the sake of our global operations, it may from time to time become necessary for us to transfer, store, and process your information within the InfiGaming group, or share it with various third party service providers outside Europe for the purposes described herein.",
                "Should we ever see fit to transfer your personal information outside of the EEA, rest assured that we will take all necessary steps and precautions to secure your information in accordance with this Privacy Policy. These safety measures may take the form of standard contractual clauses, binding corporate rules or any other methods deemed acceptable in terms of data protection measures required by the EEA.",
                "Should you wish to procure a complete list of the third-party service providers outside the EEA with access to your information, please contact us.",
            ],
        },
        {
            title: "7. Contact us",
            children: [
                "Should you have any questions regarding the InfiGaming Privacy Policy, the methods in which we handle and manage your information, or wish to exercise your rights under the Data Protection Laws, please contact our Data Protection Officer at business@infigaming.com. Alternatively, you can use the address details below to contact the Data Controller via post, or use our website contact form to send a digital request.",
            ],
        },
        {
            title: "8. Complaints",
            children: [
                "Please note that should you experience any dissatisfaction with our handling and management of your personal data, you are welcome to file a complaint with your respective Data Protection Authority.",
            ],
        },
    ];

    return (
        <div className="fixed left-0 top-0 w-full h-full bg-[#0b091a] flex flex-col justify-center">
            <div className="h-[3.75rem] sm:h-20"></div>
            <div className="w-full h-full overflow-hidden p-4 sm:p-10 sm:w-[1280px] sm:mx-auto">
                <div className="w-full h-full overflow-y-auto text-white text-sm sm:text-base font-normal">
                    <div className="text-3xl">Privacy Policy</div>
                    <div className="mt-4">
                        InfiGaming.com Limited (“infiGaming” or “we”) owns and operates the website www.infigaming.com. This Privacy Policy is intended to
                        inform you of the guidelines we have structured regarding the collection, use and disclosure of the personal data and information that
                        we gather from you via the infiGaming website. Further to this, our Privacy Policy also details the way in which we process, store and
                        protect all user data.
                    </div>
                    {contentList.map((v, key) => {
                        return (
                            <div className="mt-3" key={key}>
                                <div className="text-2xl">{v.title}</div>
                                {v.children.map((v1, key1) => {
                                    return (
                                        <div className="mt-1" key={key1}>
                                            {v1}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Privacy;
