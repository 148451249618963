import {useState, useEffect, useCallback} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Privacy from "./privacy";

import bgi from "assets/images/ifg/demo-contact/bgi.png";
import demoCityMobile from "assets/images/ifg/demo-contact/demo-city-mobile.png";
import arrowDown2 from "assets/images/ifg/arrow-down-2.png";

const RequestDemo = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isAgree, setIsAgree] = useState(true);
    const [loading, setLoading] = useState(false);

    const [isFocus, setIsFocus] = useState({name: false, company_name: false, company_url: false, phone: false, email: false, content: false});
    const [isError, setIsError] = useState({name: false, company_name: false, company_url: false, phone: false, email: false, content: false});

    const [showPrivacy, setShowPrivacy] = useState(false);

    const [userData, setUserData] = useState({
        name: "",
        company_name: "",
        company_url: "",
        phone: "",
        email: "",
        content: "",
    });

    const dataHandler = (type: "name" | "company_name" | "company_url" | "phone" | "email" | "content", e: any) => {
        const value = e.target.value;
        const obj = {};
        obj[type] = value;
        setUserData({...userData, ...obj});
    };

    const sendMessage = async () => {
        const {name, company_name, company_url, phone, email, content} = userData;
        if (!isAgree || !name || !company_name || !company_url || !phone || !email || !content) {
            toast.error("Please fill in the information completely");
            const obj = {name: false, company_name: false, company_url: false, phone: false, email: false, content: false};
            if (!name) obj["name"] = true;
            if (!company_name) obj["company_name"] = true;
            if (!company_url) obj["company_url"] = true;
            if (!phone) obj["phone"] = true;
            if (!email) obj["email"] = true;
            if (!content) obj["content"] = true;
            setIsError(obj);
            return;
        }

        setLoading(true);
        axios.defaults.baseURL = "https://api.infigaming.com";
        try {
            const res = await axios.post("/mail/request-demo", userData);

            if (res?.data?.code === 0) {
                toast.success(res?.data?.msg || "Submitted successfully");
                setUserData({
                    name: "",
                    company_name: "",
                    company_url: "",
                    phone: "",
                    email: "",
                    content: "",
                });
            } else {
                toast.error(res?.data?.msg);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleResize = useCallback(() => {
        const innerWidth = window.innerWidth;
        if (innerWidth <= 640 && !isMobile) {
            setIsMobile(true);
        }
        if (innerWidth > 640 && isMobile) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("load", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
        };
    }, [handleResize]);

    return (
        <div className="w-full h-full fixed left-0 top-0 z-10 flex flex-col" style={{backgroundImage: `url(${bgi})`, backgroundSize: "cover"}}>
            <div className={`${isMobile ? "h-[3.75rem]" : "h-20"}`}></div>
            <div className="w-full flex-1 flex items-center overflow-y-auto">
                {isMobile ? (
                    <div className="w-full h-full relative">
                        <div className="w-full px-3">
                            <img src={demoCityMobile} alt="" className="w-full block" />
                        </div>
                        <div className="w-full h-full absolute left-0 top-0" style={{paddingTop: 135, paddingBottom: 100}}>
                            <div className="w-full bg-[#151324] px-3">
                                <div className="w-full p-4" style={{border: "1px solid #3d3a4b"}}>
                                    <div className="text-[24px]">Request a demo</div>
                                    <div className="text-lg mt-3">
                                        If you are interested in finding out more about infiGaming platform technology, please fill in the form below to
                                        schedule a demo and we'll come back with our availability.
                                    </div>
                                    <div className="mt-10 w-full">
                                        <div className="w-full h-12 flex items-center mt-4" style={{backgroundColor: "#201D31"}}>
                                            <input
                                                type="text"
                                                placeholder="Name*"
                                                className={`px-3 ${isError.name ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, name: false});
                                                    setIsFocus({...isFocus, name: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, name: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    flex: 1,
                                                    height: "100%",
                                                    borderBottom: isFocus.name ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.name ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                onChange={(e) => {
                                                    dataHandler("name", e);
                                                }}
                                                value={userData.name}
                                            />
                                        </div>
                                        <div className="w-full h-12 flex items-center mt-4" style={{backgroundColor: "#201D31"}}>
                                            <input
                                                type="text"
                                                placeholder="Company Name*"
                                                className={`px-3 ${isError.company_name ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, company_name: false});
                                                    setIsFocus({...isFocus, company_name: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, company_name: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    flex: 1,
                                                    height: "100%",
                                                    borderBottom: isFocus.company_name ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.company_name ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                onChange={(e) => {
                                                    dataHandler("company_name", e);
                                                }}
                                                value={userData.company_name}
                                            />
                                        </div>
                                        <div className="w-full h-12 flex items-center mt-4" style={{backgroundColor: "#201D31"}}>
                                            <input
                                                type="text"
                                                placeholder="Company URL*"
                                                className={`px-3 ${isError.company_url ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, company_url: false});
                                                    setIsFocus({...isFocus, company_url: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, company_url: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    flex: 1,
                                                    height: "100%",
                                                    borderBottom: isFocus.company_url ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.company_url ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                onChange={(e) => {
                                                    dataHandler("company_url", e);
                                                }}
                                                value={userData.company_url}
                                            />
                                        </div>
                                        <div className="w-full h-12 flex items-center mt-4" style={{backgroundColor: "#201D31"}}>
                                            <input
                                                type="text"
                                                placeholder="Phone*"
                                                className={`px-3 ${isError.phone ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, phone: false});
                                                    setIsFocus({...isFocus, phone: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, phone: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    flex: 1,
                                                    height: "100%",
                                                    borderBottom: isFocus.phone ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.phone ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                onChange={(e) => {
                                                    dataHandler("phone", e);
                                                }}
                                                value={userData.phone}
                                            />
                                        </div>
                                        <div className="w-full h-12 flex items-center mt-4" style={{backgroundColor: "#201D31"}}>
                                            <input
                                                type="text"
                                                placeholder="Email*"
                                                className={`px-3 ${isError.email ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, email: false});
                                                    setIsFocus({...isFocus, email: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, email: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    flex: 1,
                                                    height: "100%",
                                                    borderBottom: isFocus.email ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.email ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                onChange={(e) => {
                                                    dataHandler("email", e);
                                                }}
                                                value={userData.email}
                                            />
                                        </div>
                                        <div className="w-full mt-4" style={{backgroundColor: "#201D31"}}>
                                            <textarea
                                                rows={5}
                                                className={`w-full py-3 px-3 ${isError.content ? "placeholder:text-[#FF3048]" : ""} `}
                                                onFocus={() => {
                                                    setIsError({...isError, content: false});
                                                    setIsFocus({...isFocus, content: true});
                                                }}
                                                onBlur={() => {
                                                    setIsFocus({...isFocus, content: false});
                                                }}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: "transparent",
                                                    borderBottom: isFocus.content ? "2px solid #5b51ee" : "2px solid transparent",
                                                    borderTop: isError.content ? "2px solid #FF3048" : "2px solid transparent",
                                                }}
                                                placeholder="Message Here...*"
                                                onChange={(e) => {
                                                    dataHandler("content", e);
                                                }}
                                                value={userData.content}></textarea>
                                        </div>
                                        <div className="w-full mt-4 flex items-center">
                                            <div
                                                className="w-4 h-4 flex items-center justify-center flex-shrink-0 cursor-pointer"
                                                style={{border: "1px solid #3d3a4b"}}
                                                onClick={() => {
                                                    setIsAgree(!isAgree);
                                                }}>
                                                {isAgree && <img src={arrowDown2} alt="" className="w-3 h-3" />}
                                            </div>
                                            <div className="text-base flex-1 pl-3">
                                                I have read and agree to the&nbsp;
                                                <span
                                                    className="text-[#4ADD85]"
                                                    onClick={() => {
                                                        setShowPrivacy(true);
                                                    }}>
                                                    Privacy policy
                                                </span>
                                                &nbsp; and I consent to my submitted information to be stored.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="mt-4 w-full h-12 bg-[#6149F7] flex items-center justify-center text-base font-semibold"
                                    disabled={loading}
                                    onClick={sendMessage}>
                                    Send message
                                </button>
                                <div className="w-full h-12"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-[1280px] mx-auto" style={{paddingTop: 20}}>
                        <div className="w-full bg-[#151324]" style={{padding: "20px 40px 20px 40px"}}>
                            <div className="w-full flex items-center">
                                <div className="flex-1 pr-10">
                                    <div className="text-[32px]">Request a demo</div>
                                    <div className="text-lg mt-10">
                                        If you are interested in finding out more about infiGaming platform technology, please fill in the form below to
                                        schedule a demo and we'll come back with our availability.
                                    </div>
                                </div>
                                <img src={demoCityMobile} alt="" className="object-contain flex-shrink-0" style={{height: 180}} />
                            </div>
                            <div className="w-full mt-10"></div>
                            <div className="w-full h-12 mt-4 flex items-center" style={{backgroundColor: "#201D31"}}>
                                <input
                                    type="text"
                                    placeholder="Name*"
                                    className={`px-5 ${isError.name ? "placeholder:text-[#FF3048]" : ""} `}
                                    onFocus={() => {
                                        setIsError({...isError, name: false});
                                        setIsFocus({...isFocus, name: true});
                                    }}
                                    onBlur={() => {
                                        setIsFocus({...isFocus, name: false});
                                    }}
                                    style={{
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        flex: 1,
                                        height: "100%",
                                        borderBottom: isFocus.name ? "2px solid #5b51ee" : "2px solid transparent",
                                        borderTop: isError.name ? "2px solid #FF3048" : "2px solid transparent",
                                    }}
                                    onChange={(e) => {
                                        dataHandler("name", e);
                                    }}
                                    value={userData.name}
                                />
                            </div>
                            <div className="w-full h-12 mt-4 grid grid-cols-2 grid-rows-1 gap-4">
                                <div className="w-full h-full flex items-center" style={{backgroundColor: "#201D31"}}>
                                    <input
                                        type="text"
                                        placeholder="Company Name*"
                                        className={`px-5 ${isError.company_name ? "placeholder:text-[#FF3048]" : ""} `}
                                        onFocus={() => {
                                            setIsError({...isError, company_name: false});
                                            setIsFocus({...isFocus, company_name: true});
                                        }}
                                        onBlur={() => {
                                            setIsFocus({...isFocus, company_name: false});
                                        }}
                                        style={{
                                            outline: "none",
                                            backgroundColor: "transparent",
                                            flex: 1,
                                            height: "100%",
                                            borderBottom: isFocus.company_name ? "2px solid #5b51ee" : "2px solid transparent",
                                            borderTop: isError.company_name ? "2px solid #FF3048" : "2px solid transparent",
                                        }}
                                        onChange={(e) => {
                                            dataHandler("company_name", e);
                                        }}
                                        value={userData.company_name}
                                    />
                                </div>
                                <div className="w-full h-full flex items-center" style={{backgroundColor: "#201D31"}}>
                                    <input
                                        type="text"
                                        placeholder="Company URL*"
                                        className={`px-5 ${isError.company_url ? "placeholder:text-[#FF3048]" : ""} `}
                                        onFocus={() => {
                                            setIsError({...isError, company_url: false});
                                            setIsFocus({...isFocus, company_url: true});
                                        }}
                                        onBlur={() => {
                                            setIsFocus({...isFocus, company_url: false});
                                        }}
                                        style={{
                                            outline: "none",
                                            backgroundColor: "transparent",
                                            flex: 1,
                                            height: "100%",
                                            borderBottom: isFocus.company_url ? "2px solid #5b51ee" : "2px solid transparent",
                                            borderTop: isError.company_url ? "2px solid #FF3048" : "2px solid transparent",
                                        }}
                                        onChange={(e) => {
                                            dataHandler("company_url", e);
                                        }}
                                        value={userData.company_url}
                                    />
                                </div>
                            </div>
                            <div className="w-full h-12 mt-4 grid grid-cols-2 grid-rows-1 gap-4">
                                <div className="w-full h-full flex items-center" style={{backgroundColor: "#201D31"}}>
                                    <input
                                        type="text"
                                        placeholder="Phone*"
                                        className={`px-5 ${isError.phone ? "placeholder:text-[#FF3048]" : ""} `}
                                        onFocus={() => {
                                            setIsError({...isError, phone: false});
                                            setIsFocus({...isFocus, phone: true});
                                        }}
                                        onBlur={() => {
                                            setIsFocus({...isFocus, phone: false});
                                        }}
                                        style={{
                                            outline: "none",
                                            backgroundColor: "transparent",
                                            flex: 1,
                                            height: "100%",
                                            borderBottom: isFocus.phone ? "2px solid #5b51ee" : "2px solid transparent",
                                            borderTop: isError.phone ? "2px solid #FF3048" : "2px solid transparent",
                                        }}
                                        onChange={(e) => {
                                            dataHandler("phone", e);
                                        }}
                                        value={userData.phone}
                                    />
                                </div>
                                <div className="w-full h-full flex items-center" style={{backgroundColor: "#201D31"}}>
                                    <input
                                        type="text"
                                        placeholder="Email*"
                                        className={`px-5 ${isError.email ? "placeholder:text-[#FF3048]" : ""} `}
                                        onFocus={() => {
                                            setIsError({...isError, email: false});
                                            setIsFocus({...isFocus, email: true});
                                        }}
                                        onBlur={() => {
                                            setIsFocus({...isFocus, email: false});
                                        }}
                                        style={{
                                            outline: "none",
                                            backgroundColor: "transparent",
                                            flex: 1,
                                            height: "100%",
                                            borderBottom: isFocus.email ? "2px solid #5b51ee" : "2px solid transparent",
                                            borderTop: isError.email ? "2px solid #FF3048" : "2px solid transparent",
                                        }}
                                        onChange={(e) => {
                                            dataHandler("email", e);
                                        }}
                                        value={userData.email}
                                    />
                                </div>
                            </div>
                            <div className="w-full mt-4" style={{backgroundColor: "#201D31"}}>
                                <textarea
                                    rows={3}
                                    className={`w-full py-3 px-5 ${isError.content ? "placeholder:text-[#FF3048]" : ""} `}
                                    onFocus={() => {
                                        setIsError({...isError, content: false});
                                        setIsFocus({...isFocus, content: true});
                                    }}
                                    onBlur={() => {
                                        setIsFocus({...isFocus, content: false});
                                    }}
                                    style={{
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        flex: 1,
                                        height: "100%",
                                        borderBottom: isFocus.content ? "2px solid #5b51ee" : "2px solid transparent",
                                        borderTop: isError.content ? "2px solid #FF3048" : "2px solid transparent",
                                    }}
                                    placeholder="Message Here...*"
                                    onChange={(e) => {
                                        dataHandler("content", e);
                                    }}
                                    value={userData.content}></textarea>
                            </div>
                            <div className="w-full mt-4 flex items-center">
                                <div
                                    className="w-4 h-4 flex items-center justify-center flex-shrink-0 cursor-pointer"
                                    style={{border: "1px solid #3d3a4b"}}
                                    onClick={() => {
                                        setIsAgree(!isAgree);
                                    }}>
                                    {isAgree && <img src={arrowDown2} alt="" className="w-3 h-3" />}
                                </div>
                                <div className="text-base flex-1 pl-3">
                                    I have read and agree to the&nbsp;
                                    <span
                                        className="text-[#4ADD85] cursor-pointer"
                                        onClick={() => {
                                            setShowPrivacy(true);
                                        }}>
                                        Privacy policy
                                    </span>
                                    &nbsp; and I consent to my submitted information to be stored.
                                </div>
                            </div>
                            <button
                                className="mt-4 h-12 mx-auto bg-[#6149F7] flex items-center justify-center text-base font-semibold"
                                disabled={loading}
                                onClick={sendMessage}
                                style={{width: 200}}>
                                Send message
                            </button>
                        </div>
                    </div>
                )}
                {showPrivacy && <Privacy />}
            </div>
        </div>
    );
};

export default RequestDemo;
